import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  TablePagination,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import DeleteAlert from "../AlertBox/DeleteAlert";
import EditHiringCompanyModal from "./EditHiringCompanyModal";
import {
  getHiringCompanyData,
  editHiringCompanyData,
  editCompanyStatus,
} from "../../Redux/HiringCompany/action";
import { useDispatch } from "react-redux";
import {
  IOSSwitchButton,
  SwitchButton,
} from "../ReusableComponents/SwitchButtons";
import { useColorMode } from "../../hooks/useColorMode";
import { columns } from "../../constants/hiringTab";
import ApiAlert from "../AlertBox/ApiAlert";
import { roleModulePermissions } from "../../utils/roleInstance";

const useStyles = makeStyles((theme) => ({
  editIconButton: {
    "&:hover": {
      color: "rgb(254, 154, 37)", // Set EditIcon hover text color here
    },
  },
  deleteIconButton: {
    "&:hover": {
      color: "red", // Set deleteIconButton hover text color here
    },
  },
  tableRow: {
    "& .MuiTableCell-root": {
      // borderRight: `1px solid ${theme.palette.grey[200]}`,
      borderRight: `1px solid gray`,
      borderBottom: "none",
      padding: 5,
      paddingTop: 8,
      paddingBottom: 8,
      cursor: "pointer",
      minWidth: 25,
      //this works but only for cell hover, not the full row
      "&:hover": {
        backgroundColor: "red",
      },
    },
  },
  sticky: {
    position: "sticky",
    right: 0,
  },
}));

const HiringCompanyTable = ({
  hiringComapanyData,
  // deleteHiringCompany,
  handleChangeSwitch,
  page,
  open,
  setOpen,
  hiringComapanyDataLength,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  getHiringCompanyFn,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [idToDelete, setIdToDelete] = useState(null);
  const [switchInactive, setSwitchInactive] = useState(null);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedHiringCompanyData, setSelectedHiringCompanyData] = useState(
    {}
  );
  const [alert, setAlert] = useState(false);
  const [alertContent, setalertcontent] = useState("");
  const [alertStatus, setAlertStatus] = useState(Boolean);
  const { getThemeColor } = useColorMode();
  //   console.log("selectedHiringCompanyData", selectedHiringCompanyData);

  const rows = hiringComapanyData;
  //console.log("rows", rows);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = (data) => {
    setOpen(true);
    setSwitchInactive(data);
  };

  // console.log("switchInactive",switchInactive)

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditHiringCompany = (row) => {
    setSelectedHiringCompanyData(row);
    setOpenEdit(true);
  };

  const handleOnclickEditHiringCompany = (data) => {
    // console.log("DATA TO EDIT==> ",data);
    dispatch(editHiringCompanyData(data.CompanyId, data))
      .then((res) => {
        setOpenEdit(false);
        getHiringCompanyFn();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleChangeSwitch = (data) => {
  //   console.log(data);
  //   var statusChange;
  //   if (data.companyStatus === true) {
  //     statusChange = false;
  //   } else if (data.companyStatus === false) {
  //     statusChange = true;
  //   }
  //   console.log("statusChange", statusChange);
  //   dispatch(
  //     editCompanyStatus(data.CompanyId, statusChange, {
  //       companyStatus: statusChange,
  //     })
  //   )
  //     .then((res) => {
  //       getHiringCompanyFn();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    setPage(0);
  }, [hiringComapanyDataLength]);

  const AlerthandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert(false);
  };

  // Check Role Permission
  const [hiringEditAction, sethiringEditAction] = useState("")
  const [hiringDeleteAction, sethiringDeleteAction] = useState("")

  const checkhiringEditAction = async() => { 
    const roleData = {
      roleModule : 'Hiring Company'
    }
    let rolePermission = await roleModulePermissions.checkUserRoles(roleData,'edit')
    // return rolePermission
    sethiringEditAction(rolePermission)
  }

  const checkhiringDeleteAction = async() => { 
    const roleData = {
      roleModule : 'Hiring Company'
    }
    let rolePermission = await roleModulePermissions.checkUserRoles(roleData,'delete')
    // return rolePermission
    sethiringDeleteAction(rolePermission)
  } 

  useEffect(() => {
    checkhiringEditAction()
    checkhiringDeleteAction()
  },[page])
  // Check Role Permission

  //console.log("hiring company data: ",hiringComapanyData)
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          <TableContainer
            sx={{
              // maxHeight: "415px"
              maxHeight: "calc(100vh - 170px)",
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns?.map((column) => {
                    return (
                      <TableCell
                        sx={{
                          minWidth: column.minWidth,
                          // backgroundColor: "rgb(30, 13, 97)",
                          // color: "rgb(254, 154, 37)",
                          backgroundColor: "primary.main",
                          color: "secondary.main",
                        }}
                        className={
                          (hiringEditAction != undefined && hiringEditAction != '') || (hiringDeleteAction != undefined && hiringDeleteAction != '') ? (column.id === "Action" ? classes.sticky : null) : ''
                        }
                        key={column.id}
                        align={column.align}
                      >
                        {column.id == 'Action' ? ((hiringEditAction != undefined && hiringEditAction != '') || (hiringDeleteAction != undefined && hiringDeleteAction != '') ? column.label : '') : column.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, index) => (
                  <TableRow
                    key={index}
                    //sx={{ "&:last-child td, &:last-child th": { border: 0 }}}
                    // sx={{backgroundColor:"#dddddd"}}
                    sx={{
                      backgroundColor:
                        row.companyStatus === false ? "#dddddd" : "block",
                    }}
                  >
                    <TableCell component="th" scope="row" align="right">
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell align="left">
                      {row?.name === "" ? "N/A" : row?.name}
                    </TableCell>
                    <TableCell align="left">
                      {row?.website === "" ? "N/A" : row?.website}
                    </TableCell>
                    <TableCell align="left">
                      {row?.processName === "" ? "N/A" : row?.processName}
                    </TableCell>
                    <TableCell align="left">
                      {row?.baseUrl === "" ? "N/A" : row?.baseUrl}
                    </TableCell>
                    <TableCell
                      className={(hiringEditAction != undefined && hiringEditAction != '') || (hiringDeleteAction != undefined && hiringDeleteAction != '') ? classes.sticky : ''}
                      align="center"
                      sx={{
                        cursor: "pointer",
                        backgroundColor:
                          row.companyStatus === true
                            ? "primary.background"
                            : "#dddddd",
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent={"center"}
                        alignItems={"center"}
                        spacing={row.companyStatus ? 2 : 5}
                      >
                        {hiringEditAction != '' && hiringEditAction != undefined ?
                          <Tooltip
                            title="Edit Hiring Company"
                            arrow
                            placement="top"
                          >
                            <EditOutlined
                              fontSize="medium"
                              //sx={{color:"primary.mainText"}}
                              className={classes.editIconButton}
                              onClick={() => handleEditHiringCompany(row)}
                              sx={{
                                display:
                                  row.companyStatus === true ? "block" : "none",
                              }}
                              // style={{disabled:true}}
                            />
                          </Tooltip>
                        : ''}
                        {/* <DeleteOutline
                        className={classes.deleteIconButton}
                        fontSize="medium"
                        // style={{ marginLeft: 8 }}
                        onClick={() => handleClickOpen(row.CompanyId)}
                        sx={{ display: row.companyStatus === true ? 'block' : 'none' }}
                        /> */}
                        {hiringDeleteAction != '' && hiringDeleteAction != undefined ?
                          <Tooltip
                            title={row.companyStatus ? "Disable" : "Enable"}
                            arrow
                            placement="top"
                          >
                            <IOSSwitchButton
                              size="small"
                              //onClick={() => handleChangeSwitch(row)}
                              onClick={
                                row.companyStatus === false
                                  ? () => handleChangeSwitch(row)
                                  : () => handleClickOpen(row)
                              }
                              // onClick={() => handleClickOpen(row)}
                              checked={row.companyStatus === true}
                            />
                          </Tooltip>
                        : ''}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={hiringComapanyDataLength}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {open && (
            <DeleteAlert
              open={open}
              onClose={handleClose}
              handleClose={handleClose}
              handleDelete={() => handleChangeSwitch(switchInactive)}
              rightButton={"Inactive"}
              header={"Inactive"}
              message={
                "This will make all the recruitment companies, and campaigns associated with this company inactive. Are you sure you want to make  this item inactive?"
              }
              setOpen={setOpen}
            />
          )}
        </Paper>
        {openEdit && (
          <EditHiringCompanyModal
            open={openEdit}
            setOpen={setOpenEdit}
            handleOnclickEditHiringCompany={handleOnclickEditHiringCompany}
            data={selectedHiringCompanyData}
            setAlert={setAlert}
            setalertcontent={setalertcontent}
            setAlertStatus={setAlertStatus}
          />
        )}
      </Box>
    </>
  );
};

export default HiringCompanyTable;
