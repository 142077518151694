import * as types from "./actionTypes";
import { environmentDetails } from "../../environment/environment";
import { privateAxiosInstance } from "../../utils/axiosInstance";
import { accessTokenHeadersObject } from "../../utils/headers";
import axios from "axios";

const apiUrl = environmentDetails.apiUrl;

const getHiringCompanyData =
  (page = 1, rowsPerPage = 10, compnayName = "") =>
  (dispatch) => {
    dispatch({ type: types.GET_HIRINGCOMPANY_REQUEST });
    return privateAxiosInstance
      .get(
        `/questionnaire/get-mCompany?page=${page}&pageSize=${rowsPerPage}&companyName=${compnayName}`,
        { headers: { ...accessTokenHeadersObject() } }
      )
      .then((res) => {
        return dispatch({
          type: types.GET_HIRINGCOMPANY_SUCCESS,
          payload: res.data.data,
          datalength: res.data.dataLength,
        });
      })
      .catch((err) => {
        // console.log("err", err);
        return dispatch({
          type: types.GET_HIRINGCOMPANY_FAILURE,
          payload: err,
        });
      });
  };

const getIflowHirngCompany = () => (dispatch) => {
  dispatch({ type: types.GET_IFLOWHIRINGCOMPANY_REQUEST });
  return privateAxiosInstance
    .get(
      `questionnaire/getCompanyDetails/?isActive=true&companyType=CUSTOMER`,
      { headers: { ...accessTokenHeadersObject() } }
    )
    .then((res) => {
      // console.log("res", res.data.data);
      return dispatch({
        type: types.GET_IFLOWHIRINGCOMPANY_SUCCESS,
        iflowHiringCompanydata: res.data.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: types.GET_IFLOWHIRINGCOMPANY_FAILURE,
        payload: err,
      });
    });
};

const postHiringCompanyInFlow = (data) => (dispatch) => {
  dispatch({ type: types.POST_IFLOWHIRINGCOMPANY_REQUEST });
  return privateAxiosInstance
    .post(`questionnaire/createMcomUsingAnoApi`, data, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      // console.log("postres", res);
      if (res.status === 200) {
        return dispatch({
          type: types.POST_IFLOWHIRINGCOMPANY_SUCCESS,
          dataIflowHiringCompany: res.data.data,
        });
      } else {
        return dispatch({
          type: types.POST_IFLOWHIRINGCOMPANY_FAILURE,
          error: res.response.data.message,
          // dataIflowHiringCompany: res.response,
        });
      }
    })
    .catch((err) => {
      return dispatch({
        type: types.POST_IFLOWHIRINGCOMPANY_FAILURE,
        dataIflowHiringCompany: err,
      });
    });
};
const updateIflowHiringCompany = (id,data) => (dispatch) => {
  dispatch({ type: types.UPDATE_IFLOWHIRINGCOMPANY_REQUEST });
  return privateAxiosInstance
    .put(`questionnaire/update-iflowparentcompany/${id}`,data, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      // console.log("RES.TYPE",res.data.status)
      return dispatch({
        type: types.UPDATE_IFLOWHIRINGCOMPANY_SUCCESS,
        payload:res.data,
        // idFlowHiringCompany: res.data.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: types.UPDATE_IFLOWHIRINGCOMPANY_FAILURE,
        payload:err,
        // idFlowHiringCompany: err,
      });
    });
};

const getHiringCompanyThroughData = (id) => (dispatch) => {
  dispatch({ type: types.GET_IFLOWHIRINGCOMPANYWITHID_REQUEST });
  return privateAxiosInstance
    .get(`questionnaire/getCompanyDetailsUsingData/${id}`, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      console.log("res", res.data.data);
      return dispatch({
        type: types.GET_IFLOWHIRINGCOMPANYWITHID_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: types.GET_IFLOWHIRINGCOMPANYWITHID_FAILURE,
        idFlowHiringCompany: err,
      });
    });
};

const postHiringCompanyData = (data) => (dispatch) => {
  dispatch({ type: types.POST_HIRINGCOMPANY_REQUEST });
  return privateAxiosInstance
    .post(`/questionnaire/save-mCompany`, data, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      console.log("Res", res);
      return dispatch({
        type: types.POST_HIRINGCOMPANY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.POST_HIRINGCOMPANY_FAILURE, payload: err });
    });
};

const editHiringCompanyData = (id, updateData) => (dispatch) => {
  dispatch({ type: types.EDIT_HIRINGCOMPANY_REQUEST });
  return privateAxiosInstance
    .put(`/questionnaire/update-company/${id}`, updateData, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      return dispatch({
        type: types.EDIT_HIRINGCOMPANY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({ type: types.EDIT_HIRINGCOMPANY_FAILURE, payload: err });
    });
};

const editCompanyStatus = (id, status, updateData) => (dispatch) => {
  dispatch({ type: types.EDIT_HIRINGCOMPANYSTATUS_REQUEST });
  return privateAxiosInstance
    .put(
      `/questionnaire/update-companystatus?CompanyId=${id}&status=${status}`,
      {},
      {
        headers: { ...accessTokenHeadersObject() },
      }
    )
    .then((res) => {
      return dispatch({
        type: types.EDIT_HIRINGCOMPANYSTATUS_SUCCESS,
        payload: res,
      });
    })
    .catch((err) => {
      return dispatch({
        type: types.EDIT_HIRINGCOMPANYSTATUS_FAILURE,
        payload: err,
      });
    });
};

const deleteHiringCompanyData = (id) => (dispatch) => {
  dispatch({ type: types.DELETE_HIRINGCOMPANY_REQUEST });
  return privateAxiosInstance
    .delete(`/questionnaire/delete-company/${id}`, {
      headers: { ...accessTokenHeadersObject() },
    })
    .then((res) => {
      //console.log("ReducerDelete", res);
      return dispatch({
        type: types.DELETE_HIRINGCOMPANY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: types.DELETE_HIRINGCOMPANY_FAILURE,
        payload: err,
      });
    });
};

export {
  getHiringCompanyData,
  getIflowHirngCompany,
  postHiringCompanyData,
  postHiringCompanyInFlow,
  getHiringCompanyThroughData,
  updateIflowHiringCompany,
  editHiringCompanyData,
  editCompanyStatus,
  deleteHiringCompanyData,
};
