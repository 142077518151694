import { getAccessToken, getRefreshToken } from "./localStorage";

export const accessTokenHeadersObject = () => {
    return {
      Authorization: `Bearer ${getAccessToken()}`,
    };
  };
export const refreshTokenHeadersObject = () => {
    return {
      // Authorization: `Bearer ${getRefreshToken()}`,
      Authorization: `Bearer ${getAccessToken()}`,
    };
  };
//master company --> Hiring company.
// child company --> Recruitment company.